import React from 'react'
import Landing from "./page-components/Landing";
import PremiumListings from "./page-components/PremiumListings";
import PopularListings from "./page-components/PopularListings";
import About from "./page-components/About";
import LisitingSliderComponent from './page-components/LisitingSliderComponent';
import Explore from './../Explore/Explore';
import SearchBar from './../../utils/SearchBarHome/Searchbar';
const Home = () => {
  return (
    <div className="pagemargin">   
      <Landing/>
      <PopularListings/>
      <About/>
      
    </div>
  )
}

export default Home