import React, { useEffect, useState } from "react";
import axios from "axios";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useLocation } from "react-router-dom";
import useAxios from "../../util/useAxios";
import Swal from "sweetalert2";

const VacatingForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    whatsapp_number: "",
    pg_name: "",
    pg_room_number: "",
    vacating_date: null,
    vacating_reason: "",
  });
  const location = useLocation();
  const { TenantForm } = location.state;
  const api = useAxios();

  useEffect(() => {
    // Pre-fill form data when TenantForm is available
    if (TenantForm) {
      setFormData((prevData) => ({
        ...prevData,
        name: TenantForm.name,
        whatsapp_number: TenantForm.mobile_number,
        pg_name: TenantForm.pg_name,
        pg_room_number: TenantForm.requested_room_number,
      }));
    }
  }, [TenantForm]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Replace with your API endpoint
      const response = await api.post("/dolphinpg/vacatingform/", formData);

      console.log("Vacating form submitted successfully:", response.data);

      // Display success alert using SweetAlert2
      Swal.fire({
        icon: "success",
        title: "Vacating Form Submitted Successfully!",
        showConfirmButton: false,
        timer: 2000,
      });

      // You can add additional logic or state updates upon successful submission

      // Reset the form
      setFormData({
        name: "",
        whatsapp_number: "",
        pg_name: "",
        pg_room_number: "",
        vacating_date: null,
        vacating_reason: "",
      });
    } catch (error) {
      console.error("Error submitting vacating form:", error);

      // Display error alert using SweetAlert2
      Swal.fire({
        icon: "error",
        title: "Error Submitting Vacating Form",
        text: "Please try again.",
      });

      // Handle errors or display error messages to the user
    }
  };
  return (
    <div className="container my-4 pagemargin">
      <h4 className="mb-2">Submit a Vacating Request</h4>
      <form onSubmit={handleSubmit}>
        <div className="">
          <div className="p-4 rounded bg-white js-active row justify-content-between">
            <h5 className="textorg col-md-12 text-center mb-4">
              Basic Details
            </h5>
            <div className="mb-3 d-flex gap-lg-3  align-items-center col-lg-5 col-md-12  row justify-content-center align-items-center">
              <label
                htmlFor="name"
                className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
              >
                Registered Name:<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control small-text-custom py-3 rounded-4 col text-secondary"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-3 d-flex gap-lg-3  align-items-center col-lg-5 col-md-12  row justify-content-center align-items-center">
              <label
                htmlFor="whatsapp_number"
                className="form-label text-secondary fw-normal small-text-custom col-md-4 text-nowrap"
              >
                Mobile No:<span className="text-danger">*</span>
              </label>
              <PhoneInput
                defaultCountry="IN" // Set the default country to India
                placeholder="enter your number "
                className="form-control small-text-custom py-3 rounded-4 col text-secondary"
                value={formData.whatsapp_number}
                onChange={(value) =>
                  handleChange({ target: { name: "whatsapp_number", value } })
                }
                required
              />
            </div>

            <div className="mb-3 d-flex gap-lg-3  align-items-center col-lg-5 col-md-12  row justify-content-center align-items-center">
              <label
                htmlFor="pg_name"
                className="form-label text-secondary fw-normal small-text-custom col-md-4 text-nowrap"
              >
                Tenant PG Name:<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control small-text-custom py-3 rounded-4 col text-secondary"
                id="pg_name"
                name="pg_name"
                value={formData.pg_name}
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-3 d-flex gap-lg-3  align-items-center col-lg-5 col-md-12  row justify-content-center align-items-center">
              <label
                htmlFor="pg_room_number"
                className="form-label text-secondary fw-normal small-text-custom col-md-4 text-nowrap"
              >
                Room Number:<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control small-text-custom py-3 rounded-4 col text-secondary"
                id="pg_room_number"
                name="pg_room_number"
                value={formData.pg_room_number}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="p-4 rounded bg-white js-active row justify-content-between">
            <h5 className="textorg col-md-12 text-center mb-4">
              Vacating Details
            </h5>
            <div className="mb-3 d-flex gap-lg-3  align-items-center col-lg-5 col-md-12  row justify-content-center align-items-center">
              <label
                htmlFor="vacating_date"
                className="form-label text-secondary fw-normal small-text-custom col-md-4 text-nowrap"
              >
                Vacating Date:<span className="text-danger">*</span>
              </label>
              <input
                type="date"
                className="form-control small-text-custom py-3 rounded-4 col "
                id="vacating_date"
                name="vacating_date"
                value={formData.vacating_date || ""}
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-3 d-flex gap-lg-3  align-items-center col-lg-5 col-md-12  row justify-content-center align-items-center">
              <label
                htmlFor="vacating_reason"
                className="form-label text-secondary fw-normal small-text-custom col-md-4 text-nowrap"
              >
                Vacating Reason:<span className="text-danger">*</span>
              </label>
              <textarea
                className="form-control small-text-custom py-3 rounded-4 col"
                id="vacating_reason"
                name="vacating_reason"
                placeholder="enter a valid reason"
                value={formData.vacating_reason}
                onChange={handleChange}
                required
              ></textarea>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <button type="submit" className="btn Backorg ">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default VacatingForm;
