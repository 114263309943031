import React, { useState } from "react";
import axios from "axios";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const MaintenanceForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    whatsapp_number: "",
    pg_name: "",
    pg_room_number: "",
    issue_about: "",
    photo: null,
    acknowledged: false,
  });
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({ ...prevData, photo: file }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataWithFile = new FormData();

      // Append other form data fields
      for (const key in formData) {
        formDataWithFile.append(key, formData[key]);
      }

      // Append the file
      formDataWithFile.append("photo", formData.photo);

      // Replace with your API endpoint
      const response = await axios.post(
        "https://popularpg.in/dolphinpg/maintenance/",
        formDataWithFile,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Maintenance form submitted successfully:", response.data);

      // Display success alert
      setSuccessAlert(true);
      setErrorAlert(false);

      // Reset the form
      setFormData({
        name: "",
        whatsapp_number: "",
        pg_name: "",
        pg_room_number: "",
        issue_about: "",
        photo: null,
      });
    } catch (error) {
      console.error("Error submitting Maintenance form:", error);

      // Display error alert
      setErrorAlert(true);
      setSuccessAlert(false);

      // Handle errors or display error messages to the user
    }
  };

  return (
    <div className="container my-4 pagemargin">
      {successAlert && (
        <div
          className="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          Maintenance form submitted successfully!
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => setSuccessAlert(false)}
          ></button>
        </div>
      )}
      {errorAlert && (
        <div
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          Error submitting Maintenance form. Please try again.
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => setErrorAlert(false)}
          ></button>
        </div>
      )}

      <h2 className="mb-4">Maintenance Form</h2>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6">
            <h5 className="textorg">Basic Details</h5>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Registered Name:<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="whatsapp_number" className="form-label">
                Registered Mobile Number:<span className="text-danger">*</span>
              </label>
              <PhoneInput
                defaultCountry="IN" // Set the default country to India
                placeholder="Enter only mobile number "
                value={formData.whatsapp_number}
                onChange={(value) =>
                  handleChange({ target: { name: "whatsapp_number", value } })
                }
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="pg_name" className="form-label">
                Tenant PG Name:<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="pg_name"
                name="pg_name"
                value={formData.pg_name}
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="pg_room_number" className="form-label">
                PG Room Number /Bed Name:<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="pg_room_number"
                name="pg_room_number"
                value={formData.pg_room_number}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="col-lg-6">
            <h5 className="textorg">Issue Details</h5>

            <div className="mb-3">
              <label htmlFor="issue_about" className="form-label">
                Valid Maintenance Reason:<span className="text-danger">*</span>
              </label>
              <textarea
                className="form-control"
                id="issue_about"
                name="issue_about"
                value={formData.issue_about}
                onChange={handleChange}
                required
              ></textarea>
            </div>

            <div className="mb-3">
              <label htmlFor="photo" className="form-label">
                Photo:<span className="text-danger">*</span>
              </label>
              <input
                type="file"
                className="form-control"
                id="photo"
                name="photo"
                onChange={handleFileChange}
                required
              />
            </div>
          </div>
        </div>

        <button type="submit" className="btn btn-primary ">
          Submit
        </button>
      </form>
    </div>
  );
};

export default MaintenanceForm;
