import React,{useContext} from "react";
import Logo from "../../assets/logowhite.png";
import { Link } from "react-router-dom";
import SearchContext from "../../ContextAPI/SearchContext";
const Footer = () => {
  const { setCityUrl } = useContext(
    SearchContext
  );
  return (
    <div>
      <div className="">
        <footer className="text-center text-white rounded">
          {/* first half footer  */}
          <div className="Backorg p-4 pb-0">
            <section className="">
              <div className="row d-flex justify-content-center">
                <div className="col-auto">
                  <p className="pt-2">
                    <strong>Sign up for our newsletter</strong>
                  </p>
                </div>

                <div className="col-md-5 col-12">
                  <div className="form-outline form-white mb-4">
                    <input
                      type="email"
                      id="form5Example2"
                      placeholder="Please, enter your email address."
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-auto">
                  <button
                    type="submit"
                    className="btn btn-outline-light mb-4 fw-semibold"
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </section>
          </div>
          {/* middle of the footer  */}
          

          {/* End of the footer */}
          <div className="text-center p-3 footerbg">
            © 2023 Copyright:
            <a
              className="text-white"
              style={{
                textDecoration: "none",
              }}
              href="https://popularpg.com/"
            >
              PopularPG.com
            </a>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
