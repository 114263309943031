import React from 'react';
import Styles from '../page-styles/Pgdetailslanding.module.css';
import Demo from '../../../assets/pgdetails.png';

const Landing = ({data}) => {

  const getMinPrice = (pgData) => {
    let minPrice = null;

    if (pgData && pgData.beds && pgData.beds.length > 0) {
      pgData.beds.forEach((bed) => {
        let bedPrice = parseFloat(bed.price);
        if (minPrice === null || bedPrice < minPrice) {
          minPrice = bedPrice;
        }
      });
    }

    return minPrice;
  };

  // Call getMinPrice with pgData
  let minPrice = getMinPrice(data);
  console.log(data);
  return (
    <div className={`${Styles.landingcontainer}`}>
      <div className="container-fluid">
        <div className="row d-flex justify-content-center">
          <div className={`col-md-4 img-fluid ${Styles.alignimage}`}>
            <img
              alt="Bootstrap ImagePreview"
              className={`img-fluid ${Styles.bootstrapImagePreview}`}
              src={Demo}
            />
          </div>
          <div className="col-md-8">
            <div className={`${Styles.margintop} ${Styles.transbg}`}>
              <div className="d-flex flex-wrap">
                  <h6>
                    <span className="badge Backorg mx-2 mt-3">Partner Verified</span>
                  </h6>
                {/* {data.is_brand_new && (
                  <h6>
                    <span className="badge bgwhitetextorg mx-2 mt-3">Brand New</span>
                  </h6>
                )} */}
              </div>
              <h4 className="textblue fw-semibold mx-2">₹{data.min_price} / onwards</h4>
              <h6 className="text-white mx-2">
                <b>Occupancy Type:</b> Single, Double , Triple(optional)
              </h6>
              <div className="d-flex flex-wrap">
                <h5>
                  <span className={`badge ${Styles.transbg1} mx-2 my-2`}>
                  {data.gender === 'girls' ? 'For Girls' : data.gender === 'boys' ? 'For Boys' : 'For Colive'}
                  </span>
                </h5>
                <h5>
                  <span className={`badge ${Styles.transbg1} mx-2 my-2`}>
                    For Everyone
                  </span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
