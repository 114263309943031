import React from "react";
import { FaStar } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
const Card = ({ data }) => {
  return (
    <div>
      <div className="card shadow mx-2 position-relative cardsize">
        {
          <p className="Backorg text-capitalize rounded position-absolute top-0 end-0 py-1 px-2 mx-1 mt-2">
            beds available
          </p>
        }
        {data && (
          <img
            className="card-img-top imgsize img-fluid"
            src={data.cover_image}
            alt="Card imagecap"
            style={{
              height: "200px",
              width: "400px",
              objectFit: "cover",
            }}
          />
        )}
        <div className="card-body">
          {data && (
            <small className="textorg ">
              {" "}
              <FaLocationDot /> {data.locality}
            </small>
          )}
          <h6 className="textsec text-uppercase">{data.name}</h6>
          <div className="row">
            <div className="col-8">
              <p className="card-text textorg fw-bold">
                ₹ {data.min_price} / Month
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
