import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAxios from "../../../util/useAxios";

const ProfileDashboard = () => {
  const [profile, setProfile] = useState([]);
  const [tenantId, setTenantId] = useState(null); // Initialize tenantId state
  const [tenantForm, setTenantForm] = useState([]);
  const api = useAxios();
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await api.get("/dolphinpg/user-profiles/");
      setProfile(response.data);
      const tenantIdFromData = response.data[0]?.tenant_joining_form?.id;
      setTenantId(tenantIdFromData);

      // Call fetchTenantDetails after setting the tenantId
      if (tenantIdFromData) {
        fetchTenantDetails(tenantIdFromData);
      }

      console.log(response.data);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const fetchTenantDetails = async (id) => {
    try {
      const response = await api.get(`/dolphinpg/tenantjoiningform/${id}/`);
      setTenantForm(response.data);
      console.log("the tenant form is as follow:", response.data);
    } catch (error) {
      console.error("Error fetching tenant details:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container p-4">
      <h2 className="mb-4">User Profile Dashboard</h2>
      {profile.length > 0 ? (
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">User Information</h5>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <strong>User ID:</strong> {profile[0].user.id}
              </li>
              <li className="list-group-item">
                <strong>Username:</strong> {profile[0].user.username}
              </li>
              <img
                src={profile[0].tenant_joining_form.tenant_image}
                alt={profile[0].user.username}
                className=" rounded rounded-circle object-fit-cover  "
                height="100px"
                width="100px"
              />
              <li className="list-group-item">
                <strong>Email:</strong> {profile[0].user.email}
              </li>
            </ul>
            {tenantId}
          </div>
        </div>
      ) : (
        <p>Loading profile data...</p>
      )}

      {/* Cards for Forms with React Router Links */}
      <div className="card-deck mt-4 row gap-3">
        <div className="card col-lg-4 shadow">
          <div className="card-body">
            <h5 className="card-title">Vacating Form</h5>
            <p className="card-text">Complete the vacating form.</p>

            <div
              className="btn btn-primary"
              onClick={() => {
                navigate("/vactingForm", {
                  state: {
                    TenantForm: tenantForm,
                  },
                });

                window.scroll(0, 0);
              }}
            >
              Go to Vacating Form
            </div>
          </div>
        </div>
        <div className="card col-lg-4 shadow">
          <div className="card-body">
            <h5 className="card-title">Rental Form</h5>
            <p className="card-text">Fill out the rental form.</p>
            <div
              className="btn btn-primary"
              onClick={() => {
                navigate("/rentalForm", {
                  state: {
                    TenantForm: tenantForm,
                  },
                });
              }}
            >
              Go to Rental Form
            </div>
          </div>
        </div>
        <div className="card col-lg-4 shadow">
          <div className="card-body">
            <h5 className="card-title">Maintenance Form</h5>
            <p className="card-text">Submit a maintenance request.</p>
            <Link to="/maintenanceForm" className="btn btn-primary">
              Go to Maintenance Form
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDashboard;
