import React, { useEffect, useState } from "react";
import ScheduleVist from "./page-components/scheduleVisit";
import Landing from "./page-components/landing";
import Detailscard from "./page-components/Detailscard";
import Services from "./page-components/Services";
import OtherServices from "./page-components/otherServices";
import Map from "./page-components/Map";
import axios from "axios";


const Pgdetails = () => {
  const [pg, setpg] = useState(null);

  useEffect(() => {
    const fetchdata = async () => {
      try {
        const url = new URL(window.location.href);
        const idParam = url.pathname.split("/")[2];
        const response = await axios.get(`https://popularpg.in/dolphinpg/properties/${idParam}/`);
        console.log(response.data);
        setpg(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchdata();
  }, []);

  const servicesData = pg?.amenities?.map((amenity) => ({ name: amenity.amenity_name })) || [];
  console.log(servicesData);

  return (
    <div className="pagemargin">
      {pg && <Landing data={pg} />}
      <div className="container-fluid">
        <div className="row mx-4">
          <div className="col-md-8 my-3">
            <div className="shadow rounded">
              {pg && <Detailscard data={pg} />}
            </div>
            <div className="my-2 rounded">
              <Services servicesData={servicesData} />
            </div>
          </div>
          <div className="col-md-4">
            <ScheduleVist />
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default Pgdetails;
