import React, { useEffect, useState } from "react";
import axios from "axios";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useLocation } from "react-router-dom";
import useAxios from "../../util/useAxios";
import Swal from "sweetalert2";

const RentalForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    pg_name: "",
    room_number: "",
    deposit: null,
    rent: null,
    mobile_number: "",
    payment_mode: "",
    payment_image: null,
    acknowledged: false,
  });

  const location = useLocation();
  const { TenantForm } = location.state;
  const api = useAxios();

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "rent" || name === "deposit") {
      // Convert rent and deposit values to numbers
      const rent = name === "rent" ? parseFloat(value) : formData.rent || 0;
      const deposit =
        name === "deposit" ? parseFloat(value) : formData.deposit || 0;

      setFormData((prevData) => ({
        ...prevData,
        [name]: parseFloat(value), // Ensure numeric value is set
        total_amount: rent + deposit,
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSelectChange = (e) => {
    // Update payment_mode in the state
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({ ...prevData, payment_image: file }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Create form data object to handle file upload
      const formDataWithFile = new FormData();
      for (const key in formData) {
        formDataWithFile.append(key, formData[key]);
      }

      // Send data to the API endpoint
      await api.post("/dolphinpg/rental-form/", formDataWithFile);

      Swal.fire({
        icon: "success",
        title: "Rental Form Submitted Successfully!",
        showConfirmButton: false,
        timer: 2000,
      });

      // Reset form data
      setFormData({
        name: "",
        pg_name: "",
        room_number: "",
        deposit: null,
        rent: null,
        total_amount: null,
        mobile_number: "",
        payment_mode: "",
        amount_paid: "",
        transaction_id: "",
        payment_image: null,
        acknowledged: false,
      });

      window.scrollTo(0, 0);
    } catch (error) {
      // Handle error
      console.error("Error submitting form:", error);

      Swal.fire({
        icon: "error",
        title: "Error Submitting Rental Form",
        text: "Please try again.",
      });
    }
  };

  useEffect(() => {
    // Pre-fill form data when TenantForm is available
    if (TenantForm) {
      setFormData((prevData) => ({
        ...prevData,
        name: TenantForm.name,
        whatsapp_number: TenantForm.mobile_number,
        pg_name: TenantForm.pg_name,
        room_number: TenantForm.requested_room_number,
        deposit: TenantForm.security_deposit,
        rent: TenantForm.monthly_rent,
        mobile_number: TenantForm.mobile_number,
      }));
    }
  }, [TenantForm]);

  return (
    <div className="container my-5 pagemargin">
      <h4>Submit a Rental Form</h4>
      <form onSubmit={handleSubmit}>
        <div className="">
          <div className="p-4 rounded bg-white js-active row justify-content-between">
            <h5 className="textorg col-md-12 text-center mb-4">
              Basic details (Pre-Filled)
            </h5>

            <div className="mb-3 d-flex gap-lg-3  align-items-center col-lg-5 col-md-12  row justify-content-center align-items-center">
              <label
                htmlFor="name"
                className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
              >
                Tenant Registered Name:<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="form-control small-text-custom py-3 rounded-4 col text-secondary"
                required
              />
            </div>
            <div className="mb-3 d-flex gap-lg-3  align-items-center col-lg-5 col-md-12  row justify-content-center align-items-center">
              <label
                htmlFor="pg_name"
                className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
              >
                Tenant PG Name:<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="pg_name"
                name="pg_name"
                value={formData.pg_name}
                onChange={handleChange}
                className="form-control small-text-custom py-3 rounded-4 col text-secondary"
                required
              />
            </div>
            <div className="mb-3 d-flex gap-lg-3  align-items-center col-lg-5 col-md-12  row justify-content-center align-items-center">
              <label
                htmlFor="room_number"
                className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
              >
                Tenant Room Number:<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="room_number"
                name="room_number"
                value={formData.room_number}
                onChange={handleChange}
                className="form-control small-text-custom py-3 rounded-4 col text-secondary"
                required
              />
            </div>
            <div className="mb-3 d-flex gap-lg-3  align-items-center col-lg-5 col-md-12  row justify-content-center align-items-center">
              <label
                htmlFor="deposit"
                className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
              >
                Deposit Amount:<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="deposit"
                name="deposit"
                value={formData.deposit}
                onChange={handleChange}
                className="form-control small-text-custom py-3 rounded-4 col text-secondary"
                required
              />
            </div>
            <div className="mb-3 d-flex gap-lg-3  align-items-center col-lg-5 col-md-12  row justify-content-center align-items-center">
              <label
                htmlFor="rent"
                className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
              >
                Rent:<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="rent"
                name="rent"
                value={formData.rent}
                onChange={handleChange}
                className="form-control small-text-custom py-3 rounded-4 col text-secondary"
                required
              />
            </div>

            <div className="mb-3 d-flex gap-lg-3  align-items-center col-lg-5 col-md-12  row justify-content-center align-items-center">
              <label
                htmlFor="mobile_number"
                className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
              >
                Registered Mobile Number:<span className="text-danger">*</span>
              </label>
              <PhoneInput
                defaultCountry="IN" // Set the default country to India
                className="form-control small-text-custom py-3 rounded-4 col text-secondary"
                placeholder="Enter only mobile number "
                value={formData.mobile_number}
                onChange={(value) =>
                  handleChange({ target: { name: "mobile_number", value } })
                }
                required
              />
            </div>
          </div>
          <div className="p-4 rounded bg-white js-active row justify-content-between">
            <h5 className="textorg col-md-12 text-center mb-4">
              Rental Details
            </h5>
            <div className="mb-3 d-flex gap-lg-3  align-items-center col-lg-5 col-md-12  row justify-content-center align-items-center">
              <label
                htmlFor="payment_image"
                className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
              >
                Rent Payment Image:<span className="text-danger">*</span>
              </label>
              <input
                type="file"
                id="payment_image"
                name="payment_image"
                onChange={handleFileChange}
                className="form-control small-text-custom py-3 rounded-4 text-secondary px-5 col "
                required
              />
            </div>

            <div className="mb-3 d-flex gap-lg-3  align-items-center col-lg-5 col-md-12  row justify-content-center align-items-center">
              <label
                htmlFor="amount_paid"
                className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
              >
                Amount Paid:<span className="text-danger">*</span>
              </label>
              <input
                type="number"
                id="amount_paid"
                name="amount_paid"
                value={formData.amount_paid}
                onChange={handleChange}
                className="form-control small-text-custom py-3 rounded-4 col text-secondary"
                required
              />
            </div>
            <div className="mb-3 d-flex gap-lg-3  align-items-center col-lg-5 col-md-12  row justify-content-center align-items-center">
              <label
                htmlFor="transaction_id"
                className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
              >
                Transaction Id:<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="transaction_id"
                name="transaction_id"
                value={formData.transaction_id}
                onChange={handleChange}
                className="form-control small-text-custom py-3 rounded-4 col text-secondary"
                required
              />
            </div>
            <div className="mb-3 d-flex gap-lg-3  align-items-center col-lg-5 col-md-12  row justify-content-center align-items-center">
              <label
                htmlFor="payment_mode"
                className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
              >
                Payment Mode:<span className="text-danger">*</span>
              </label>
              <select
                name="payment_mode"
                id="payment_mode"
                className="form-control small-text-custom py-3 rounded-4 col text-secondary "
                value={formData.payment_mode}
                onChange={handleSelectChange}
              >
                <option value="">--select--</option>
                <option value="cash">Cash</option>
                <option value="upi">UPI</option>
                <option value="banktransfer">Bank Transfer</option>
                <option value="credit">Credit</option>
                <option value="others">Other Payment Mode</option>
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button type="submit" className="btn Backorg ">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RentalForm;
