import React, { useState } from "react";
import Styles from "../page-styles/detailscard.module.css";
import axios from "axios";

const Detailscard = ({ data }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [contactData, setContactData] = useState({
    name: "",
    phone: "",
  });

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  const handleSubmit = async () => {
    // Prepare the data to be sent
    const requestData = {
      name: contactData.name || "",  // Use empty string instead of null
      phone: contactData.phone || "",
      property: data.id,  // Assuming `data.id` is the property ID
    };
  
    try {
      // Send the request using Axios
      const response = await axios.post(
        "https://popularpg.in/dolphinpg/bookings/",
        requestData
      );
  
      // Handle the response as needed
      console.log("Booking response:", response.data);
  
      // Optionally, you can close the modal or perform other actions here
  
      // Reset the form after successful submission
      setContactData({
        name: "",
        phone: "",
      });
  
      // Show success alert
      alert("Form submitted successfully!");
    } catch (error) {
      // Handle errors
      console.error("Error submitting form:", error);
  
      // Show error alert
      alert("Failed to submit form. Please try again.");
    }
  };

  return (
    <div className="container-fluid my-3 rounded">
      <img
        src={data.property_images[selectedImageIndex].image}
        className="card-img-top img-fluid rounded m-2"
        alt="Big image"
        style={{
          height: "400px",
          width: "100%",
          objectFit: "cover",
        }}
      />
      <div className="card-body">
        <div className="row mx-1">
          {data.property_images.map((image, index) => (
            <div key={index} className="col-2 p-2 ">
              <img
                src={image.image}
                className="img-fluid rounded "
                alt={`SmallImage ${index + 1}`}
                onClick={() => handleImageClick(index)}
                style={{ cursor: "pointer" }}
              />
            </div>
          ))}
        </div>
        <h3 className="card-title textsec">{data.name}</h3>
        <small className="text-muted ">{data.street_address}</small>
        <small className="text-muted ">,{data.pincode}</small>
        <small className="text-muted ">,{data.locality}</small>

        <div className="form-group mx-4 my-3">
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input "
              id="termsAndConditions"
              checked
              muted
            />
            <label className="form-check-label" htmlFor="termsAndConditions">
              We have very big spacious room and our PG is in the heart of the
              city, cleanly maintained paying guest with hygiene maintained.
            </label>
          </div>
        </div>
        <div className="d-flex  mx-2 my-2 ">
          <button   
            type="button"
            className="btn Backorg mx-1 my-2 "
            data-bs-toggle="modal"
            data-bs-target="#exampleModalCenter"
          >
            Get a Call Back
          </button>
          <a
            href={`tel:${data.phone}`}
            className={`btn outlineorg mx-1 my-2 ${Styles.smallScreen}`}
          >
            Contact Owner
          </a>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="-1"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="d-flex flex-wrap justify-content-between mx-3 mt-2">
              <h5 className="modal-title ">Contact Owner</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <label
                  htmlFor="PGname"
                  className="fs-4 "
                  style={{ marginTop: "-1rem" }}
                >
                  <b>{data ? data.name : null}</b>
                </label>
                <p
                  className="text-dark"
                  style={{
                    fontSize: "0.9rem",
                  }}
                >
                  {data && data.locality}
                </p>
                <div className="form-group">
                  <label htmlFor="name" className="modal-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Enter your name"
                    onChange={(e) =>
                      setContactData({
                        ...contactData,
                        name: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="phone" className="modal-label">
                    Phone Number
                  </label>
                  <input
                    type="tel" // Use type "tel" for phone numbers
                    className="form-control"
                    id="phone"
                    placeholder="Enter your phone number"
                    onChange={(e) =>
                      setContactData({
                        ...contactData,
                        phone: e.target.value,
                      })
                    }
                  />
                </div>
                
              </form>
              <button
                type="button"
                className="btn my-2 Backorg"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detailscard;
