import React, { useEffect, useState } from 'react';
import SearchContext from './SearchContext';
import axios from 'axios';

// Move sortData outside the SearchState component
const sortData = (data, sortType) => {
  if (sortType === 'asc') {
    return [...data].sort((a, b) => parseInt(a.min_price) - parseInt(b.min_price));
  } else if (sortType === 'desc') {
    return [...data].sort((a, b) => parseInt(b.min_price) - parseInt(a.min_price));
  }
  return data;
};

const SearchState = ({ children }) => {
  const [pgs, setpgs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [location, setLocation] = useState([]);
  const [gender, setGender] = useState([]);
  const [occupancytype, setOccupancyType] = useState([]);
  const [sortType, setSortType] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [cityUrl, setCityUrl] = useState(''); 
  const [selectedPG, setSelectedPG] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = cityUrl ? `https://popularpg.in/dolphinpg/properties/${cityUrl}`: `https://popularpg.in/dolphinpg/properties/?page=${currentPage}`;
        const response = await axios.get(url);
        setpgs(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
    console.log('page number ' + currentPage);
  }, [currentPage,cityUrl]);

  const setloadingfalseafter3sec = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const handleSearch = async () => {
    try {
      let url = 'https://popularpg.in/dolphinpg/properties/?';

      if (gender.value) {
        url += `pg_for=${gender.value}&`;
      }
  
      if (location.value) {
        url += `locality=${location.value}`;
      }
  
      const response = await axios.get(url);
      let filteredData = response.data.results;
      console.log('filtered data:', filteredData);

      // Apply budget-wise filtering
      if (occupancytype.value === 'single') {
        filteredData = filteredData?.filter((pg) => pg.single_sharing === true);
      } else if (occupancytype.value === 'double') {
        filteredData = filteredData?.filter((pg) => pg.double_sharing === true);
      } else if (occupancytype.value === 'triple') {
        filteredData = filteredData?.filter((pg) => pg.triple_sharing=== true);
      }

      // Apply sorting
      filteredData = sortData(filteredData, sortType.value);
      setpgs(filteredData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <SearchContext.Provider
      value={{
        pgs,
        setpgs,
        isLoading,
        setIsLoading,
        setloadingfalseafter3sec,
        data,
        setData,
        location,
        setLocation,
        gender,
        setGender,
        occupancytype,
        setOccupancyType,
        sortType,
        setSortType,
        handleSearch,
        setCurrentPage,
        currentPage,
        cityUrl, setCityUrl,
        selectedPG, setSelectedPG
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchState;
