import React, { useState, useEffect, useContext } from "react";
import Logo from "../../assets/Dolphin.png";
import { Link, useLocation } from "react-router-dom";
import Styles from "./Navbar.module.css";
import SmallscreenNavbar from "./SmallscreenNavbar";
import SearchContext from "../../ContextAPI/SearchContext";
import AuthContext from "./../../../Context/AuthContext";
const NavBar = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const { setCityUrl } = useContext(SearchContext);
  const { user, logoutUser } = useContext(AuthContext);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [NavbarView, setNavbarView] = useState(
    windowWidth < 900 ? true : false
  );
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setNavbarView(windowWidth < 900 ? true : false);
  }, [windowWidth]);

  return (
    <div className={`header-bottom `}>
      {NavbarView ? (
        <div
          className="navbar z-3"
          style={{
            zIndex: 10,
          }}
        >
          <SmallscreenNavbar />
        </div>
      ) : (
        <nav
          className={`navbar navbar-expand-lg navbar-light fixed-top bg-light  ${Styles.navheight} `}
        >
          <div className="container-fluid">
            <Link to="/" className={`navbar-brand ${Styles.logoContainer}`}>
              <img
                src={Logo}
                alt="Logo"
                height="45"
                width="100"
                className={`d-inline-block align-top img-fluid ${Styles.logoimg}`}
              />
            </Link>

            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleNavbar}
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
              id="navbarNav"
            >
              <ul className="navbar-nav mx-auto">
                <li className={`nav-item ${Styles.orghover} mx-2`}>
                  <Link
                    to="/"
                    className={`nav-link text-black ${
                      location.pathname === "/" ? Styles.active : ""
                    }`}
                  >
                    Home
                  </Link>
                </li>
                <li className={`nav-item ${Styles.orghover} mx-2`}>
                  <Link
                    to="/explore"
                    className={`nav-link text-black ${
                      location.pathname === "/explore" ? Styles.active : ""
                    }`}
                  >
                    Explore Pg
                  </Link>
                </li>

                <li className={`nav-item ${Styles.orghover} mx-2`}>
                  <Link
                    to="/profileDashboard"
                    className={`nav-link text-black ${
                      location.pathname === "/profileDashboard"  
                        ? Styles.active
                        : ""
                    }`}
                  >
                    Profile
                  </Link>
                </li>
              </ul>
              <div className="navbar-nav">
                <div className="nav-item text-nowrap">
                  {user && user ? (
                    <button
                      className="btn btn-danger px-3 pointer-cursor mx-3"
                      onClick={logoutUser}
                    >
                      Sign out
                    </button>
                  ) : (
                    <button className="btn btn-success px-3 pointer-cursor mx-3">
                      <Link
                        to="/login"
                        className="text-white text-decoration-none"
                      >
                        Sign in
                      </Link>
                    </button>
                  )}
                </div>
              </div>

              <a className="btn outlineorg me-2" href="tel:9008778999">
                Contact: 9008778999
              </a>
            </div>
          </div>
        </nav>
      )}
    </div>
  );
};

export default NavBar;
