import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const TenantJoiningForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const currentDate = new Date();
  const oneMonthLater = new Date(currentDate);
  oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);

  const defaultVacatingDate = `${oneMonthLater.getFullYear()}-${(
    oneMonthLater.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${oneMonthLater.getDate().toString().padStart(2, "0")}`;

  const [formData, setFormData] = useState({
    name: queryParams.get("name") || "",
    father_name: queryParams.get("father_name") || "",
    mother_name: queryParams.get("mother_name") || "",
    requested_room_number: queryParams.get("requested_room_number") || "",
    pg_name: queryParams.get("pg_name") || "",
    date_of_joining: queryParams.get("date_of_joining") || null,
    date_of_birth: queryParams.get("date_of_birth") || null,
    mobile_number: queryParams.get("mobile_number") || "",
    father_mobile_number: queryParams.get("father_mobile_number") || "",
    mother_mobile_number: queryParams.get("mother_mobile_number") || "",
    education_qualification: queryParams.get("education_qualification") || "",
    email_id: queryParams.get("email_id") || "",
    company_name: queryParams.get("company_name") || "",
    aadhar_number: queryParams.get("aadhar_number") || "",
    office_address: queryParams.get("office_address") || "",
    residential_address: queryParams.get("residential_address") || "",
    security_deposit: queryParams.get("security_deposit") || null,
    monthly_rent: queryParams.get("monthly_rent") || null,
    maintenance_charges: queryParams.get("maintenance_charges") || null,
    joining_date: queryParams.get("joining_date") || null,
    vacating_date: queryParams.get("vacating_date") || defaultVacatingDate,
    tenant_image: null,
    aadhar_image_upload_front: null,
    aadhar_image_upload_back: null,
    agreeTerms: false,
  });

  const [address, setAddress] = useState({
    office_address_1: "",
    office_address_2: "",
    office_address_city: "",
    office_address_state: "",
    office_address_pincode: "",
    residential_address_1: "",
    residential_address_2: "",
    residential_address_city: "",
    residential_address_state: "",
    residential_address_pincode: "",
  });
  const [alertMessage, setAlertMessage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Handle office_address fields separately
    if (
      name === "office_address_1" ||
      name === "office_address_2" ||
      name === "office_address_city" ||
      name === "office_address_state" ||
      name === "office_address_pincode"
    ) {
      setAddress((prevAddress) => ({
        ...prevAddress,
        [name]: value,
      }));
    } else if (
      // Handle residential_address fields separately
      name === "residential_address_1" ||
      name === "residential_address_2" ||
      name === "residential_address_city" ||
      name === "residential_address_state" ||
      name === "residential_address_pincode"
    ) {
      setAddress((prevAddress) => ({
        ...prevAddress,
        [name]: value,
      }));
    } else {
      // For other fields not related to address, update the formData as usual
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleImageChange = (e, fieldName) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: file,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();

      // Append other fields to formDataToSend
      Object.entries(formData).forEach(([key, value]) => {
        if (
          key !== "tenant_image" &&
          key !== "aadhar_image_upload_front" &&
          key !== "aadhar_image_upload_back" &&
          key !== "office_address" &&
          key !== "residential_address"
        ) {
          formDataToSend.append(key, value);
        }
      });

      // Concatenate office_address fields into office_address
      formDataToSend.append(
        "office_address",
        `${address.office_address_1}#${address.office_address_2}#${address.office_address_city}#${address.office_address_state}#${address.office_address_pincode}`
      );

      // Concatenate residential_address fields into residential_address
      formDataToSend.append(
        "residential_address",
        `${address.residential_address_1}#${address.residential_address_2}#${address.residential_address_city}#${address.residential_address_state}#${address.residential_address_pincode}`
      );

      formDataToSend.append("tenant_image", formData.tenant_image);
      formDataToSend.append(
        "aadhar_image_upload_front",
        formData.aadhar_image_upload_front
      );
      formDataToSend.append(
        "aadhar_image_upload_back",
        formData.aadhar_image_upload_back
      );

      const response = await axios.post(
        "https://popularpg.in/dolphinpg/tenantjoiningform/",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Display success alert using SweetAlert2
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        icon: "success",
        title: "Success!",
        text: "Tenant joining form submitted successfully",
      });

      console.log("Tenant joining form submitted successfully:", response.data);
      // You can add additional logic or state updates upon successful submission

      // Clear form data and address
      setFormData({
        name: "",
        father_name: "",
        mother_name: "",
        date_of_joining: null,
        date_of_birth: null,
        mobile_number: "",
        education_qualification: "",
        email_id: "",
        company_name: "",
        aadhar_number: "",
        office_address: "",
        residential_address: "",
        joining_date: null,
        tenant_image: null,
        aadhar_image_upload_front: null,
        aadhar_image_upload_back: null,
        agreeTerms: false,
      });

      setAddress({
        office_address_1: "",
        office_address_2: "",
        office_address_city: "",
        office_address_state: "",
        office_address_pincode: "",
        residential_address_1: "",
        residential_address_2: "",
        residential_address_city: "",
        residential_address_state: "",
        residential_address_pincode: "",
      });

      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      // Display error alert using SweetAlert2

      const errorMessage = error.response.data;

      // Set error message to alertMessage state
      console.log(errorMessage);
      let Errortext = "Error in the code "
      if(errorMessage.date_of_joining){
        Errortext = "Incorrect Date of Joining"
      }
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        icon: "error",
        title: "Error!",
        text: `Error submitting tenant joining form . Please try again.`,
      });

      console.error("Error submitting tenant joining form:", error.response);
      
        
      // Handle errors or display error messages to the user
    }
  };
  /* error.response &&
        error.response.data &&
        error.response.data.user &&
        error.response.data.user.username &&
        error.response.data.user.username */

  const steps = [
    "Basic Details",
    "Address & Official Details",
    "Requested PG Details",
    "PG Rules and Regulations",
  ];

  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
    window.scrollTo(0, 0); // Scrolls to the top of the page
  };

  const handlePrevious = () => {
    setCurrentStep((prevStep) => prevStep - 1);
    window.scrollTo(0, 0); // Scrolls to the top of the page
  };

  const isLastStep = currentStep === steps.length - 1;

  const stepsRef = useRef(null);

  return (
    <div className="container my-4 pagemargin ">
      {alertMessage && (
        <div className="alert alert-danger" role="alert">
          {alertMessage}
        </div>
      )}
      <h4 className="mb-4 text-center">Tenant Joining Form</h4>

      <div className="multisteps-form__progress mb-4">
        {steps.map((step, index) => (
          <button
            key={index}
            className={`multisteps-form__progress-btn ${
              currentStep >= index ? "js-active" : ""
            }`}
            type="button"
            title={step}
          >
            {step}
          </button>
        ))}
      </div>
      <div className="d-flex justify-content-center align-items-between">
        <div className="multisteps-form__form_body">
          <form onSubmit={handleSubmit} className="multisteps-form__form">
            {currentStep === 0 && (
              <div
                className="multisteps-form__panel shadow p-4 rounded bg-white js-active row justify-content-between"
                data-animation="scaleIn"
              >
                <h5 className="textorg col-md-12 text-center mb-4">
                  Basic Details
                </h5>
                <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center ">
                  <label
                    htmlFor="name"
                    className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                  >
                    Name:<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control small-text-custom py-3 rounded-4 col"
                    id="name"
                    name="name"
                    placeholder="enter your name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                  <label
                    htmlFor="date_of_birth"
                    className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                  >
                    Date of Birth:<span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control small-text-custom py-3 rounded-4 text-secondary col"
                    id="date_of_birth"
                    name="date_of_birth"
                    value={formData.date_of_birth || ""}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                  <label
                    htmlFor="mobile_number"
                    className=" text-secondary fw-normal small-text-custom  col-md-4 col-sm-12 text-nowrap"
                  >
                    Mobile Number:<span className="text-danger">*</span>
                  </label>
                  <br />
                  <PhoneInput
                    className="form-control small-text-custom py-3 rounded-4 col"
                    defaultCountry="IN" // Set the default country to India
                    placeholder="enter your number"
                    value={formData.mobile_number}
                    onChange={(value) =>
                      handleChange({ target: { name: "mobile_number", value } })
                    }
                    required
                  />
                </div>

                <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                  <label
                    htmlFor="email_id"
                    className=" text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                  >
                    Email ID:<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control small-text-custom py-3 rounded-4 col"
                    placeholder="enter valid email"
                    id="email_id"
                    name="email_id"
                    value={formData.email_id}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                  <label
                    htmlFor="education_qualification"
                    className=" text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                  >
                    Education Qualification:
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control small-text-custom py-3 rounded-4 col"
                    id="education_qualification"
                    name="education_qualification"
                    value={formData.education_qualification}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 rounded-3 row justify-content-center align-items-center">
                  <label
                    htmlFor="tenant_image"
                    className=" text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                  >
                    Tenant Image:
                    <span className="text-danger">*</span>
                  </label>

                  <input
                    type="file"
                    className="form-control small-text-custom py-3 rounded-4 text-secondary px-5 col "
                    id="tenant_image"
                    name="tenant_image"
                    onChange={(e) => handleImageChange(e, "tenant_image")}
                  />
                </div>

                <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                  <label
                    htmlFor="date_of_joining"
                    className=" text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                  >
                    Date of Joining:<span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control small-text-custom py-3 rounded-4 text-secondary col"
                    id="date_of_joining"
                    name="date_of_joining"
                    value={formData.date_of_joining || ""}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="d-flex justify-content-end">
                  {isLastStep ? (
                    <button
                      type="submit"
                      className={`btn btn-primary ${
                        !formData.agreeTerms && "disabled"
                      }`}
                      disabled={!formData.agreeTerms}
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary ml-auto js-btn-next"
                      title="Next"
                      onClick={handleNext}
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            )}

            {/* Your form content for Address & Official Details */}
            {currentStep === 1 && (
              <div
                className="multisteps-form__panel shadow p-4 rounded bg-white js-active row justify-content-between"
                data-animation="scaleIn"
              >
                <h5 className="textorg col-md-12 text-center">
                  Address & Official Details
                </h5>
                <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                  <label
                    htmlFor="mother_name"
                    className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                  >
                    Mother Name:<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control small-text-custom py-3 rounded-4 col"
                    id="mother_name"
                    name="mother_name"
                    placeholder="enter name"
                    value={formData.mother_name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                  <label
                    htmlFor="mother_mobile_number"
                    className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                  >
                    Mother Mobile Number:<span className="text-danger">*</span>
                  </label>
                  <br />
                  <PhoneInput
                    className="form-control small-text-custom py-3 rounded-4 col"
                    defaultCountry="IN" // Set the default country to India
                    placeholder="enter your number"
                    value={formData.mother_mobile_number}
                    onChange={(value) =>
                      handleChange({
                        target: { name: "mother_mobile_number", value },
                      })
                    }
                    required
                  />
                </div>
                <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                  <label
                    htmlFor="father_name"
                    className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                  >
                    Father Name:<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control small-text-custom py-3 rounded-4 col"
                    id="father_name"
                    name="father_name"
                    placeholder="enter name"
                    value={formData.father_name}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                  <label
                    htmlFor="father_mobile_number"
                    className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                  >
                    Father Mobile Number:<span className="text-danger">*</span>
                  </label>
                  <br />
                  <PhoneInput
                    className="form-control small-text-custom py-3 rounded-4 col"
                    defaultCountry="IN" // Set the default country to India
                    placeholder="enter your number"
                    value={formData.father_mobile_number}
                    onChange={(value) =>
                      handleChange({
                        target: { name: "father_mobile_number", value },
                      })
                    }
                    required
                  />
                </div>
                <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                  <label
                    htmlFor="aadhar_number"
                    className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                  >
                    Aadhar Number:<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control small-text-custom py-3 rounded-4 col"
                    id="aadhar_number"
                    name="aadhar_number"
                    placeholder="enter valid aadhar number"
                    value={formData.aadhar_number}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                  <label
                    htmlFor="aadhar_image_upload_front"
                    className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                  >
                    Aadhar Upload Front : <span className="text-danger">*</span>
                  </label>
                  <input
                    type="file"
                    className="form-control small-text-custom py-3 rounded-4 col px-5"
                    id="aadhar_image_upload_front"
                    name="aadhar_image_upload_front"
                    onChange={(e) =>
                      handleImageChange(e, "aadhar_image_upload_front")
                    }
                    required
                  />
                </div>
                <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                  <label
                    htmlFor="aadhar_image_upload_back"
                    className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                  >
                    Aadhar Upload Back : <span className="text-danger">*</span>
                  </label>
                  <input
                    type="file"
                    id="aadhar_image_upload_back"
                    className="form-control small-text-custom py-3 rounded-4 col px-5"
                    name="aadhar_image_upload_back"
                    onChange={(e) =>
                      handleImageChange(e, "aadhar_image_upload_back")
                    }
                    required
                  />
                </div>
                <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                  <label
                    htmlFor="company_name"
                    className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                  >
                    Company / College Name:
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control small-text-custom py-3 rounded-4 col"
                    id="company_name"
                    name="company_name"
                    placeholder="enter company name"
                    value={formData.company_name}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="mb-3 row justify-content-between border pt-4 rounded-3">
                  <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                    <label
                      htmlFor="office_address_1"
                      className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                    >
                      Office / College Address :
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control small-text-custom py-3 rounded-4 col"
                      id="office_address_1"
                      name="office_address_1"
                      placeholder="address line 1"
                      value={address.office_address_1}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                    <label
                      htmlFor="office_address_2"
                      className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                    >
                      Office / College Address :
                    </label>
                    <input
                      type="text"
                      className="form-control small-text-custom py-3 rounded-4 col"
                      id="office_address_2"
                      name="office_address_2"
                      placeholder="address line 2 (optional)"
                      value={address.office_address_2}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                    <label
                      htmlFor="office_address_city"
                      className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                    >
                      Office / College City :
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control small-text-custom py-3 rounded-4 col"
                      id="office_address_city"
                      name="office_address_city"
                      placeholder="city"
                      value={address.office_address_city}
                      required
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                    <label
                      htmlFor="office_address_state"
                      className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                    >
                      Office / College State :
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control small-text-custom py-3 rounded-4 col"
                      id="office_address_state"
                      name="office_address_state"
                      placeholder="state"
                      value={address.office_address_state}
                      required
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                    <label
                      htmlFor="office_address_pincode"
                      className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                    >
                      Office / College Pincode :
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control small-text-custom py-3 rounded-4 col"
                      id="office_address_pincode"
                      name="office_address_pincode"
                      placeholder="enter valid pincode"
                      value={address.office_address_pincode}
                      required
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="mb-3 row justify-content-between border pt-4 rounded-3">
                  <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                    <label
                      htmlFor="residential_address_1"
                      className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                    >
                      Permenant Address:
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control small-text-custom py-3 rounded-4 col"
                      id="residential_address_1"
                      name="residential_address_1"
                      placeholder="address line 1"
                      value={address.residential_address_1}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                    <label
                      htmlFor="residential_address_2"
                      className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                    >
                      Permenant Address :
                    </label>
                    <input
                      type="text"
                      className="form-control small-text-custom py-3 rounded-4 col"
                      id="residential_address_2"
                      name="residential_address_2"
                      placeholder="address line 2 (optional)"
                      value={address.residential_address_2}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                    <label
                      htmlFor="residential_address_city"
                      className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                    >
                      City :<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control small-text-custom py-3 rounded-4 col"
                      id="residential_address_city"
                      name="residential_address_city"
                      placeholder="city"
                      value={address.residential_address_city}
                      required
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                    <label
                      htmlFor="residential_address_state"
                      className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                    >
                      State :<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control small-text-custom py-3 rounded-4 col"
                      id="residential_address_state"
                      name="residential_address_state"
                      placeholder="state"
                      value={address.residential_address_state}
                      required
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                    <label
                      htmlFor="residential_address_pincode"
                      className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                    >
                      Pincode :<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control small-text-custom py-3 rounded-4 col"
                      id="residential_address_pincode"
                      name="residential_address_pincode"
                      placeholder="enter valid pincode"
                      value={address.residential_address_pincode}
                      required
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="button-row d-flex justify-content-between mt-4">
                  {currentStep > 0 && (
                    <button
                      type="button"
                      className="btn btn-secondary js-btn-prev"
                      title="Prev"
                      onClick={handlePrevious}
                    >
                      Previous
                    </button>
                  )}

                  {isLastStep ? (
                    <button
                      type="submit"
                      className={`btn btn-primary ${
                        !formData.agreeTerms && "disabled"
                      }`}
                      disabled={!formData.agreeTerms}
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary ml-auto js-btn-next"
                      title="Next"
                      onClick={handleNext}
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            )}

            {currentStep === 2 && (
              <div
                className="multisteps-form__panel shadow p-4 rounded bg-white js-active row justify-content-between"
                data-animation="scaleIn"
              >
                <h5 className="textorg col-md-12 text-center">
                  Requested PG Details (cannot be changed)
                </h5>
                <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                  <label
                    htmlFor="requested_room_number"
                    className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                  >
                    Requested Room Number:
                  </label>
                  <input
                    type="text"
                    className="form-control small-text-custom py-3 rounded-4 col"
                    id="requested_room_number"
                    name="requested_room_number"
                    value={formData.requested_room_number}
                    onChange={handleChange}
                    disabled
                  />
                </div>

                <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                  <label
                    htmlFor="pg_name"
                    className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                  >
                    PG Name:
                  </label>
                  <input
                    type="text"
                    className="form-control small-text-custom py-3 rounded-4 col"
                    id="pg_name"
                    name="pg_name"
                    value={formData.pg_name}
                    onChange={handleChange}
                    disabled
                  />
                </div>

                <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                  <label
                    htmlFor="security_deposit"
                    className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                  >
                    Security Deposit:
                  </label>
                  <input
                    type="number"
                    className="form-control small-text-custom py-3 rounded-4 col"
                    id="security_deposit"
                    name="security_deposit"
                    value={formData.security_deposit || ""}
                    onChange={handleChange}
                    disabled
                  />
                </div>

                <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                  <label
                    htmlFor="monthly_rent"
                    className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                  >
                    Monthly Rent:
                  </label>
                  <input
                    type="number"
                    className="form-control small-text-custom py-3 rounded-4 col"
                    id="monthly_rent"
                    name="monthly_rent"
                    value={formData.monthly_rent || ""}
                    onChange={handleChange}
                    disabled
                  />
                </div>

                <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
                  <label
                    htmlFor="maintenance_charges"
                    className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap"
                  >
                    Maintenance Charges:
                  </label>
                  <input
                    type="number"
                    className="form-control small-text-custom py-3 rounded-4 col"
                    id="maintenance_charges"
                    name="maintenance_charges"
                    value={formData.maintenance_charges || ""}
                    onChange={handleChange}
                    disabled
                  />
                </div>

                {/* <div className="mb-3 d-flex gap-lg-3 align-items-center col-lg-5 col-md-12 row justify-content-center align-items-center">
              <label htmlFor="vacating_date" className="form-label text-secondary fw-normal small-text-custom col-md-4 col-sm-12 text-nowrap">
                Expecting Vacating Date:(default 1 month - change accordingly )
              </label>
              <input
                type="date"
                className="form-control small-text-custom py-3 rounded-4 col"
                id="vacating_date"
                name="vacating_date"
                value={formData.vacating_date || ""}
                onChange={handleChange}
              />
            </div> */}
                <div className="button-row d-flex justify-content-between mt-4">
                  {currentStep > 0 && (
                    <button
                      type="button"
                      className="btn btn-secondary js-btn-prev"
                      title="Prev"
                      onClick={handlePrevious}
                    >
                      Previous
                    </button>
                  )}

                  {isLastStep ? (
                    <button
                      type="submit"
                      className={`btn btn-primary ${
                        !formData.agreeTerms && "disabled"
                      }`}
                      disabled={!formData.agreeTerms}
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary ml-auto js-btn-next"
                      title="Next"
                      onClick={handleNext}
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            )}

            {currentStep === 3 && (
              <div
                className="multisteps-form__panel shadow p-4 rounded bg-white js-active row justify-content-between"
                data-animation="scaleIn"
              >
                <h3 className="mb-4 text-danger col-md-12 text-center">
                  PG Rules and Regulations
                </h3>
                <div className="terms-and-conditions mb-4">
                  <ul className="list-group">
                    <li className="list-group-item fw-normal small-text-custom text-secondary">
                      Rent must be paid on or before the 3rd of every month. The
                      penalty of Rs. 50/- will be charged per day for late
                      payments.
                    </li>
                    <li className="list-group-item fw-normal small-text-custom text-secondary">
                      Complete rent has to be paid, and no concessions for
                      partial stay or partial consumption of food or any other
                      reasons.
                    </li>
                    <li className="list-group-item fw-normal small-text-custom text-secondary">
                      Booking amount is nonrefundable.
                    </li>
                    <li className="list-group-item fw-normal small-text-custom text-secondary">
                      If the reservation is canceled, the money paid will not be
                      reimbursed.
                    </li>
                    <li className="list-group-item fw-normal small-text-custom text-secondary">
                      At the time of vacating, the maintenance charge of Rs.
                      2000/- will be deducted from the security deposit.
                    </li>
                    <li className="list-group-item fw-normal small-text-custom text-secondary">
                      The cost of guest accommodations for parents or friends is
                      Rs. 700/- per day per person.
                    </li>
                    <li className="list-group-item fw-normal small-text-custom text-secondary">
                      If any tenant creates any issues, the management can
                      terminate the tenant by giving 5 days of notice period or
                      immediately.
                    </li>
                    <li className="list-group-item fw-normal small-text-custom text-secondary">
                      We offer a residential broadband connection with
                      high-speed internet on a sharing basis. Tenant can take
                      their broadband for personal use.
                    </li>
                    <li className="list-group-item fw-normal small-text-custom text-secondary">
                      In the case of any technical issues, the rental fee will
                      not be reduced.
                    </li>
                    <li className="list-group-item fw-normal small-text-custom text-secondary">
                      It's strictly prohibited to smoke or consume alcohol or
                      any banned drugs in the building premises.
                    </li>
                    <li className="list-group-item fw-normal small-text-custom text-secondary">
                      Loss of valuables is not the responsibility of the
                      management.
                    </li>
                    <li className="list-group-item fw-normal small-text-custom text-secondary">
                      One Month of notice period is a must, failing which you
                      have to pay 20 days of rent as compensation before
                      vacating, excluding maintenance.
                    </li>
                    <li className="list-group-item fw-normal small-text-custom text-secondary">
                      If the main door and cupboard keys are lost, you will be
                      responsible for paying for duplicate keys, lock, and any
                      damages to the door.
                    </li>
                    <li className="list-group-item fw-normal small-text-custom text-secondary">
                      If any damage to the property belongings occurs, the
                      concerned person should bear the cost of the damaged
                      material.
                    </li>
                    <li className="list-group-item fw-normal small-text-custom text-secondary">
                      During shared stays or notice periods, Admin/Office staff
                      may enter your room to display it to new guests.
                    </li>
                    <li className="list-group-item fw-normal small-text-custom text-secondary">
                      Regular checks and cleaning in the room may happen
                      anytime, either in your presence or absence.
                    </li>
                    <li className="list-group-item fw-normal small-text-custom text-secondary">
                      Management is not responsible for your personal or
                      financial relationship with PG. Secure your belongings in
                      the cupboard. Pine Management owns the right to the
                      decision.
                    </li>
                  </ul>
                </div>
                <div className="form-check mb-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="agreeTerms"
                    name="agreeTerms"
                    checked={formData.agreeTerms}
                    onChange={handleChange}
                    required
                  />
                  <label className="form-check-label" htmlFor="agreeTerms">
                    I have read and agree to all the terms and conditions with
                    pg rules and regulations{" "}
                    <span className="text-danger">*</span>
                  </label>
                </div>
                <p className="text-secondary">
                  fields containing '<span className="text-danger">*</span>' are
                  the required fields
                </p>

                <div className="button-row d-flex justify-content-between mt-4">
                  {currentStep > 0 && (
                    <button
                      type="button"
                      className="btn btn-secondary js-btn-prev"
                      title="Prev"
                      onClick={handlePrevious}
                    >
                      Previous
                    </button>
                  )}

                  {isLastStep ? (
                    <button
                      type="submit"
                      className={`btn btn-primary ${
                        !formData.agreeTerms && "disabled"
                      }`}
                      disabled={!formData.agreeTerms}
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary ml-auto js-btn-next"
                      title="Next"
                      onClick={handleNext}
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default TenantJoiningForm;
