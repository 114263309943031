import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Card from "../../../utils/Card/Card";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import axios from "axios";
import useAxios from "./../../../../util/useAxios";

const PopularListings = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [slidetoshow, setSlidetoshow] = useState(windowWidth < 600 ? 1 : 3);
  const [PopularPGs, setPopularPGs] = useState([]);
  const api = useAxios();

  useEffect(() => {
    const fetchpremiumList = async () => {
      const PopularPGs = await axios.get(
        "https://popularpg.in/dolphinpg/properties/"
      );
      setPopularPGs(PopularPGs);
    };
    fetchpremiumList();
  }, []);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setSlidetoshow(windowWidth < 600 ? 1 : 3);
  }, [windowWidth]);

  const settings = {
    infinite: true,
    slidesToShow: slidetoshow,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };
  return (
    <div className="bgyellow  overflow-hidden">
      <div className="zig-zag"></div>
      <div className="d-flex justify-content-center m-2">
        <h1>
          {" "}
          <span className="textorg">Our</span>{" "}
          <span className="textcoffe">Listings</span>{" "}
        </h1>
      </div>
      <br />
      <div
        className=" align-items-center "
        style={{
          width: "80%",
          marginLeft: "10%",
        }}
      >
        <div className="">
          <Slider {...settings}>
            {PopularPGs &&
              PopularPGs.data &&
              PopularPGs.data.map((data) => {
                return (
                  <div key={data.id}>
                    <div className="d-flex justify-content-center my-3">
                      <Link
                        to={`/pgdetails/${data.id}`}
                        className="text-decoration-none "
                      >
                        <Card key={data.id} data={data} />
                      </Link>
                    </div>
                  </div>
                );
              })}
          </Slider>
        </div>
      </div>
      <br />
      <br />
      <div className="d-flex justify-content-center my-2 ">
        <Link className="btn Backorg m-2 px-4 py-2 " to="/explore">
          View All
        </Link>
      </div>
    </div>
  );
};

export default PopularListings;
