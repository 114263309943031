import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Login from "../../assets/login1.png";
import Logo from "../../assets/logo.png";
import AuthContext from "./../../../Context/AuthContext";

const LoginForm = () => {
  const { loginUser } = useContext(AuthContext);

  return (
    <div className="container my-5 pagemargin">
      <div className="row d-flex justify-content-center">
        <div className="col-md-6 d-flex justify-content-center">
          <img src={Login} alt="Login" className="img-fluid loginimg" />
        </div>

        <div className="col-md-6">
          <div className="rounded shadow p-4">
            <div className="text-center ">
              <img src={Logo} alt="Logo" className="img-fluid" width="200" />
            </div>
            <p className="text-muted text-center">Welcome Back!!</p>
            <h2 className="fw-bold text-center">Sign in</h2>
            {/* Mobile Number Input */}
            <form onSubmit={loginUser} className="col-md-6 mx-auto">
              <div className="mb-3">
                <label htmlFor="username" className="form-label">
                  Username:
                </label>
                <input
                  type="text"
                  name="username"
                  className="form-control"
                  placeholder="Enter your username"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Password:
                </label>
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  placeholder="Enter your password"
                />
              </div>
              <button type="submit" className="btn Backorg">
                Login
              </button>
            </form>

            {/* Redirect to Sign In */}
            <p className="mt-3 text-center text-muted">
              Not registered?
              <Link to="/register" className="textorg">
                Sign In
              </Link>
            </p>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default LoginForm;
