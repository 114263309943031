import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useState } from "react";

function Example() {
  const [value, setValue] = useState();

  const handleSubmit = () => {
    console.log("Submitted phone number:", value);
    // Add additional logic or API call if needed
  };

  return (
    <div className="pagemargin">
      <div className="col-3">
        {" "}
        <PhoneInput
          placeholder="Enter phone number"
          value={value}
          onChange={setValue}
        />
      </div>

      <button onClick={handleSubmit}>Submit</button>
    </div>
  );
}

export default Example;
